<template>
  <lazy-load
    class="product-qty-and-cart-btn product-box__buttons"
    v-if="!productData.currentlyFree"
  >
    <template v-if="productData.soldoutRelation">
      <template v-if="productData.soldoutRelation.type === 'SUCCESSOR' && addSuccessorActive">
        <counter-input
          class="product-box__counter"
          :counter="productData.counter"
          @update="$emit('quantityChange', $event)"
          ref="quantity"
        />
        <button
          aria-label="add successor to basket"
          class="product-box__button-to-cart | button button--successor-to-basket"
          :class="{
            'button--loading': state === 'loadingBasket'
          }"
          :disabled="state === 'loadingBasket'"
          type="button"
          @click="$emit('addToBasket', $refs.quantity.value)"
        >
          {{ $t('availability.available.successor.addToBasket') }}
        </button>
      </template>
      <template v-else>
        <a
          class="product-box__status product-box__status--successor"
          :href="soldoutRelationUrl"
        >
          {{ $t('ads.ads_top_info.go_to.' + productData.soldoutRelation.type) }}
        </a>
      </template>
    </template>
    <template v-else-if="!productData.availability.available && productLink">
      <a
        class="product-box__status product-box__status--unavailable"
        :href="productLink"
      >
        {{ productData.availability.statement }}
      </a>
    </template>
    <template v-else-if="productData.purchaseOnRequestArticle && productData.valid">
      <span
        class="product-box__status product-box__status--on-request"
        @click="$emit('onRequestClicked', $event)"
      >
        {{ $t('ads.ads_top_info.article_on_request') }}
      </span>
    </template>
    <template v-else-if="!recurringBuyable && productData.recurringOrder && productLink">
      <a
        class="button product-box__button--to-product"
        :href="productLink"
      >
        {{ $t('productbox.abo.product.link') }}
      </a>
    </template>
    <template v-else-if="productData.valid">
      <counter-input
        class="product-box__counter"
        :counter="productData.counter"
        @update="$emit('quantityChange', $event)"
        ref="quantity"
      />
      <button
        class="product-box__button-to-cart | button button--add-to-basket"
        :class="{
          'button--loading': state === 'loadingBasket',
          [cartButtonClass]: cartButtonClass !== null
        }"
        :disabled="state === 'loadingBasket'"
        type="button"
        @click="$emit('addToBasket', $refs.quantity.value)"
        :aria-label="$t('ads.ads_top_info.add_to_basket')"
      >
        {{ cartButtonText }}
      </button>
    </template>
  </lazy-load>
  <div
    v-else
    class="product-qty-and-cart-btn product-box__buttons--currently-free-product-box"
  />
</template>

<script lang="ts">
  import {Vue, Options, Prop} from 'vue-property-decorator';
  import LazyLoad from '../lazyLoad/lazyLoad.vue';
  import type ProductBoxData from '../../types/ProductBoxData';
  import {ProductBoxSettings} from '../productBox/productBoxSettings';
  import diContainer from '../../utilities/js/diContainer/diContainer';

  @Options({
    name: 'product-qty-and-cart-btn',
    components: {LazyLoad}
  })
  export default class ProductQtyAndCartBtn extends Vue {
    @Prop({required: true})
    productData: ProductBoxData
    @Prop({required: true})
    productLink: string;
    @Prop({default: true})
    addSuccessorActive: boolean
    @Prop({required: true})
    state: string;
    @Prop({default: false})
    recurringBuyable: string;
    @Prop()
    cartButtonClass: string;
    @Prop()
    cartButtonText: string;
    settings: ProductBoxSettings = diContainer.get('productBoxSettings');

    get soldoutRelationUrl () {
      if (this.settings.referredByHash) {
        return this.productData.soldoutRelation.url + this.settings.referredByHash + this.productData.trackingData.wban;
      }
      return this.productData.soldoutRelation.url;
    }
  }
</script>
