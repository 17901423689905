class FormSettings {
  checkboxIsSwitch: boolean;
  remainSignedIn: boolean;
  addressForm: {
    showPhone: boolean,
  }
}
const formSettings = new FormSettings();

formSettings.checkboxIsSwitch = false;
formSettings.remainSignedIn = false;
formSettings.addressForm = {
  showPhone: true,
};

export {formSettings, FormSettings};
