import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelDynamic as _vModelDynamic, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["aria-label"]
const _hoisted_2 = {
  key: 0,
  class: "counter__input counter__input--disabled"
}
const _hoisted_3 = ["type", "name", "disabled", "min", "max"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", {
    class: _normalizeClass(["counter", {
      'counter--active': _ctx.hasHover || _ctx.hasFocus,
      'counter--disabled': _ctx.disableInputEdit,
    }]),
    onMouseover: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.mouseOverHandler && _ctx.mouseOverHandler(...args))),
    onMouseleave: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.mouseOutHandler && _ctx.mouseOutHandler(...args))),
    ref: "wrapper",
    "aria-label": _ctx.$t('checkout.basket.position.header.quantity') + ' ' + _ctx.value
  }, [
    (_ctx.disableInputEdit)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.value), 1 /* TEXT */))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _withDirectives(_createElementVNode("input", {
            type: _ctx.type,
            inputmode: "numeric",
            class: "counter__input",
            name: _ctx.name,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
            onKeyup: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.keyUpHandler && _ctx.keyUpHandler(...args))),
            onFocus: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.focusHandler && _ctx.focusHandler(...args))),
            onBlur: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.blurHandler && _ctx.blurHandler(...args))),
            disabled: _ctx.disableInputEdit,
            min: _ctx.minQuantity,
            max: _ctx.maxQuantity,
            ref: "input",
            autocomplete: "off",
            autocorrect: "off",
            tabindex: "-1"
          }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_3), [
            [_vModelDynamic, _ctx.value]
          ]),
          _createTextVNode(),
          _createElementVNode("button", {
            "aria-label": "+1",
            class: _normalizeClass(["counter__button counter__button--increase", {
          'counter__button--disabled': this.value === this.maxQuantity
        }]),
            onClick: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.increase && _ctx.increase(...args)), ["prevent"]))
          }, null, 2 /* CLASS */),
          _createTextVNode(),
          _createElementVNode("button", {
            "aria-label": "-1",
            class: _normalizeClass(["counter__button counter__button--decrease", {
          'counter__button--disabled': this.value === this.minQuantity
        }]),
            onClick: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.decrease && _ctx.decrease(...args)), ["prevent"]))
          }, null, 2 /* CLASS */)
        ], 64 /* STABLE_FRAGMENT */))
  ], 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_1))
}