import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "product-box__rating-container"
}
const _hoisted_2 = { class: "product-box__product-flags" }
const _hoisted_3 = ["href"]
const _hoisted_4 = ["href"]
const _hoisted_5 = {
  key: 0,
  class: "product-box__tags-wrapper"
}
const _hoisted_6 = { class: "product-box__image-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_flags = _resolveComponent("flags")!
  const _component_ratings = _resolveComponent("ratings")!
  const _component_image_element = _resolveComponent("image-element")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tag), { class: "product-box__segment-image" }, {
    default: _withCtx(() => [
      (_ctx.isBoxTypeHorizontal)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_flags, {
                class: _normalizeClass({'product-box__product-flags--hidden': _ctx.productBoxData.inBasket}),
                flags: _ctx.productBoxData.flags,
                limit: 3,
                "award-limit": 1,
                "white-list": _ctx.settings.flags.whitelist
              }, null, 8 /* PROPS */, ["class", "flags", "white-list"])
            ]),
            _createTextVNode(),
            _createElementVNode("div", null, [
              _createElementVNode("div", null, [
                (_ctx.productBoxData.ratings > _ctx.minimumRating && !_ctx.productBoxData.inBasket)
                  ? (_openBlock(), _createBlock(_component_ratings, {
                      key: 0,
                      class: "product-box__ratings",
                      rating: _ctx.productBoxData.ratings,
                      "ratings-counter": _ctx.productBoxData.ratingsCounter,
                      type: _ctx.settings.rating.type
                    }, null, 8 /* PROPS */, ["rating", "ratings-counter", "type"]))
                  : _createCommentVNode("v-if", true),
                _createTextVNode(),
                (_ctx.productBoxData.inBasket)
                  ? (_openBlock(), _createElementBlock("a", {
                      key: 1,
                      class: "product-box__status product-box__status--basket product-box__status--basket-up",
                      href: _ctx.basketUrl
                    }, _toDisplayString(_ctx.$t('ads.ads_top_info.article_in_basket')), 9 /* TEXT, PROPS */, _hoisted_3))
                  : _createCommentVNode("v-if", true),
                _createTextVNode(),
                (_ctx.productBoxData.successorInBasket && !_ctx.productBoxData.inBasket && _ctx.addSuccessorActive)
                  ? (_openBlock(), _createElementBlock("a", {
                      key: 2,
                      class: "product-box__status product-box__status--basket product-box__status--basket-up",
                      href: _ctx.basketUrl
                    }, _toDisplayString(_ctx.$t('ads.ads_top_info.successor_in_basket')), 9 /* TEXT, PROPS */, _hoisted_4))
                  : _createCommentVNode("v-if", true)
              ]),
              _createTextVNode(),
              (_ctx.productBoxData.tags && !_ctx.productBoxData.inBasket)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createVNode(_component_flags, {
                      class: _normalizeClass({'product-box__product-flags--hidden': _ctx.productBoxData.inBasket}),
                      flags: _ctx.productBoxData.flags,
                      limit: 3,
                      "award-limit": 1,
                      "white-list": _ctx.settings.flags.whitelistTag
                    }, null, 8 /* PROPS */, ["class", "flags", "white-list"])
                  ]))
                : _createCommentVNode("v-if", true)
            ])
          ]))
        : _createCommentVNode("v-if", true),
      _createTextVNode(),
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.productLink ? 'a' : 'div'), {
        class: "product-box__image-container",
        href: _ctx.productLink,
        target: _ctx.productLink && _ctx.target ? _ctx.target : null
      }, {
        default: _withCtx(() => [
          (_ctx.productBoxData.price && _ctx.productBoxData.flags && _ctx.isBoxTypeVertical)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["product-box__product-flags", {
          'product-box__product-flags--hasSpecialFlags': _ctx.hasSpecialFlags
        }]),
                role: "presentation"
              }, [
                _createVNode(_component_flags, {
                  class: _normalizeClass({'product-box__product-flags--hidden': _ctx.productBoxData.inBasket}),
                  flags: _ctx.productBoxData.flags,
                  limit: 4,
                  "award-limit": 2,
                  "white-list": _ctx.settings.flags.whitelist
                }, null, 8 /* PROPS */, ["class", "flags", "white-list"])
              ], 2 /* CLASS */))
            : _createCommentVNode("v-if", true),
          _createTextVNode(),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_image_element, {
              class: "product-box__image",
              src: _ctx.imgSrc,
              alt: _ctx.productBoxData.images[0].alt
            }, null, 8 /* PROPS */, ["src", "alt"])
          ])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["href", "target"]))
    ]),
    _: 1 /* STABLE */
  }))
}