import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["data-id", "data-item-id", "data-artnr", "data-wban", "aria-label"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "product-box__body" }
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_product_box_segment_review = _resolveComponent("product-box-segment-review")!
  const _component_product_box_segment_header = _resolveComponent("product-box-segment-header")!
  const _component_product_box_segment_image = _resolveComponent("product-box-segment-image")!
  const _component_product_box_segment_main = _resolveComponent("product-box-segment-main")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["product-box", {
      'product-box--in-basket': _ctx.productBoxData.inBasket,
      'product-box--error': _ctx.state === 'error',
      'product-box--type-horizontal': _ctx.isBoxTypeHorizontal,
      'product-box--type-vertical': _ctx.isBoxTypeVertical,
      'product-box--type-bottle': _ctx.isBoxBottle,
      'product-box--personalization': _ctx.productBoxData.personalPreferenceMessageKey && !_ctx.showReview,
      [`product-box--type-${_ctx.productBoxData.productBoxType}`]: true,
      'product-box--highlight': _ctx.productBoxData.highlightArticle,
    }]),
    "data-id": _ctx.productBoxData.id,
    "data-item-id": _ctx.productBoxData.itemId,
    "data-artnr": _ctx.productBoxData.trackingData.artNr,
    "data-wban": _ctx.productBoxData.trackingData.wban,
    role: "article",
    "aria-label": _ctx.productBoxData.name
  }, [
    (_ctx.productBoxData.highlightArticle)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "product-box__highlight-text",
          innerHTML: _ctx.$t(`highlightArticle.title.${_ctx.productBoxData.highlightArticle}`)
        }, null, 8 /* PROPS */, _hoisted_2))
      : _createCommentVNode("v-if", true),
    _createTextVNode(),
    (_ctx.showReview)
      ? (_openBlock(), _createBlock(_component_product_box_segment_review, {
          key: 1,
          "product-box-data": _ctx.productBoxData,
          "product-link": _ctx.productLink
        }, null, 8 /* PROPS */, ["product-box-data", "product-link"]))
      : _createCommentVNode("v-if", true),
    _createTextVNode(),
    _createVNode(_component_product_box_segment_header, {
      "product-box-data": _ctx.productBoxData,
      "is-wishlist-enabled": _ctx.isWishlistEnabled,
      state: _ctx.state,
      "product-link": _ctx.productLink,
      "show-review": _ctx.showReview,
      onWishlistClicked: _ctx.toggleWishlist
    }, null, 8 /* PROPS */, ["product-box-data", "is-wishlist-enabled", "state", "product-link", "show-review", "onWishlistClicked"]),
    _createTextVNode(),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_product_box_segment_image, {
        "product-box-data": _ctx.productBoxData,
        settings: _ctx.settings,
        images: _ctx.productBoxData.images,
        "is-box-type-horizontal": _ctx.isBoxTypeHorizontal,
        "is-box-type-vertical": _ctx.isBoxTypeVertical,
        "minimum-rating": _ctx.minimumRating,
        "basket-url": _ctx.basketUrl,
        "product-link": _ctx.productLink,
        state: _ctx.state,
        "add-successor-active": _ctx.addSuccessorActive,
        "lazy-load": _ctx.lazyLoad
      }, null, 8 /* PROPS */, ["product-box-data", "settings", "images", "is-box-type-horizontal", "is-box-type-vertical", "minimum-rating", "basket-url", "product-link", "state", "add-successor-active", "lazy-load"]),
      _createTextVNode(),
      _createVNode(_component_product_box_segment_main, {
        "product-box-data": _ctx.productBoxData,
        settings: _ctx.settings,
        "basket-url": _ctx.basketUrl,
        "product-link": _ctx.productLink,
        "food-specification-link": _ctx.foodSpecificationLink,
        "subscription-link": _ctx.subscriptionLink,
        "hide-counter-button": _ctx.hideCounterButton,
        "is-box-type-vertical": _ctx.isBoxTypeVertical,
        "minimum-rating": _ctx.minimumRating,
        "show-availability": _ctx.showAvailability,
        state: _ctx.state,
        "hide-price": _ctx.isPriceHidden,
        "add-successor-active": _ctx.addSuccessorActive,
        onAddToBasket: _ctx.addToBasket,
        onQuantityChange: _ctx.changeQuantity,
        onOnRequestClicked: _ctx.openOnRequestLightBox
      }, null, 8 /* PROPS */, ["product-box-data", "settings", "basket-url", "product-link", "food-specification-link", "subscription-link", "hide-counter-button", "is-box-type-vertical", "minimum-rating", "show-availability", "state", "hide-price", "add-successor-active", "onAddToBasket", "onQuantityChange", "onOnRequestClicked"])
    ]),
    _createTextVNode(),
    (_ctx.productBoxData.scoringInformation)
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          innerHTML: _ctx.productBoxData.scoringInformation
        }, null, 8 /* PROPS */, _hoisted_4))
      : _createCommentVNode("v-if", true)
  ], 10 /* CLASS, PROPS */, _hoisted_1))
}