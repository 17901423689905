import {ref} from 'vue';

/**
 * Global record of translations.
 * Changes in this object will trigger reactivity in all components using the getText and keyExists function.
 */
const translations = ref<Record<string, string>>({});

/**
 * Set translation keys and values
 * @param newTranslations
 */
function setTranslations(newTranslations: Record<string, string>) {
  translations.value = newTranslations;
}

/**
 * Check if a translation key exists
 * @param key
 */
function keyExists(key: string): boolean {
  return key in translations.value;
}

/**
 * Translate a key with optional parameters and optional pluralization
 * @param key
 * @param params
 * @param count
 */
function getText(key: string, params: string[]|Record<string, string> = null, count: number = null) {
  const textKey = findKey(key, count);
  if (textKey === null) {
    return '';
  }

  let translation = translations.value[textKey];

  if (params && Object.keys(params).length) {
    translation = translation.replace(/\{([^}]+)}/g, (match, key) => params[key] ?? match);
  }

  return translation;
}

/**
 * Find the correct key for a given count.
 * Returns null if no key is found
 * @param key
 * @param count
 */
function findKey(key: string, count: number): string|null {
  if (count !== null) {
    let countedKey: string;
    if (count === 1) {
      countedKey = `${key}.one`;
    }
    else if (count > 1) {
      countedKey = `${key}.other`;
    }

    if (keyExists(countedKey)) {
      return countedKey;
    }
  }

  return keyExists(key) ? key : null;
}


export {
  getText as t,
  keyExists as te,
  setTranslations
}
