import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["aria-label"]
const _hoisted_2 = {
  key: 0,
  class: "availability-info--label"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "availability-info",
    role: "note",
    "aria-label": _ctx.availability.statement
  }, [
    (_ctx.availability)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass(["availability-info__label", {
        'availability-info--statement': _ctx.availability.statement,
        'availability-info__label--available': _ctx.availability.status === 'AVAILABLE',
        'availability-info__label--later-available': _ctx.availability.status === 'LATER_AVAILABLE',
        'availability-info__label--sold-out': _ctx.availability.status === 'NOT_AVAILABLE'
      }])
        }, [
          (_ctx.availability.statement)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.availability.statement), 1 /* TEXT */))
            : _createCommentVNode("v-if", true),
          _createTextVNode(),
          _renderSlot(_ctx.$slots, "default")
        ], 2 /* CLASS */))
      : _createCommentVNode("v-if", true)
  ], 8 /* PROPS */, _hoisted_1))
}