<template>
  <div
    class="availability-info"
    role="note"
    :aria-label="availability.statement"
  >
    <span
      class="availability-info__label"
      v-if="availability"
      :class="{
        'availability-info--statement': availability.statement,
        'availability-info__label--available': availability.status === 'AVAILABLE',
        'availability-info__label--later-available': availability.status === 'LATER_AVAILABLE',
        'availability-info__label--sold-out': availability.status === 'NOT_AVAILABLE'
      }"
    >
      <span
        class="availability-info--label"
        v-if="availability.statement"
      >
        {{ availability.statement }}
      </span>
      <slot />
    </span>
  </div>
</template>

<script lang="ts">
  import {defineComponent, PropType} from 'vue';
  import type Availability from '../../types/Availability';

  export default defineComponent({
    name: 'AvailabilityInfo',
    props: {
      availability: {
        type: Object as PropType<Availability>,
        required: true
      }
    }
  });
</script>
