<template>
  <div
    class="product-box"
    :class="{
      'product-box--in-basket': productBoxData.inBasket,
      'product-box--error': state === 'error',
      'product-box--type-horizontal': isBoxTypeHorizontal,
      'product-box--type-vertical': isBoxTypeVertical,
      'product-box--type-bottle': isBoxBottle,
      'product-box--personalization': productBoxData.personalPreferenceMessageKey && !showReview,
      [`product-box--type-${productBoxData.productBoxType}`]: true,
      'product-box--highlight': productBoxData.highlightArticle,
    }"
    :data-id="productBoxData.id"
    :data-item-id="productBoxData.itemId"
    :data-artnr="productBoxData.trackingData.artNr"
    :data-wban="productBoxData.trackingData.wban"
    role="article"
    :aria-label="productBoxData.name"
  >
    <div
      v-if="productBoxData.highlightArticle"
      class="product-box__highlight-text"
      v-html="$t(`highlightArticle.title.${productBoxData.highlightArticle}`)"
    />
    <product-box-segment-review
      :product-box-data="productBoxData"
      :product-link="productLink"
      v-if="showReview"
    />
    <product-box-segment-header
      :product-box-data="productBoxData"
      :is-wishlist-enabled="isWishlistEnabled"
      :state="state"
      :product-link="productLink"
      :show-review="showReview"
      @wishlistClicked="toggleWishlist"
    />
    <div class="product-box__body">
      <product-box-segment-image
        :product-box-data="productBoxData"
        :settings="settings"
        :images="productBoxData.images"
        :is-box-type-horizontal="isBoxTypeHorizontal"
        :is-box-type-vertical="isBoxTypeVertical"
        :minimum-rating="minimumRating"
        :basket-url="basketUrl"
        :product-link="productLink"
        :state="state"
        :add-successor-active="addSuccessorActive"
        :lazy-load="lazyLoad"
      />

      <product-box-segment-main
        :product-box-data="productBoxData"
        :settings="settings"
        :basket-url="basketUrl"
        :product-link="productLink"
        :food-specification-link="foodSpecificationLink"
        :subscription-link="subscriptionLink"
        :hide-counter-button="hideCounterButton"
        :is-box-type-vertical="isBoxTypeVertical"
        :minimum-rating="minimumRating"
        :show-availability="showAvailability"
        :state="state"
        :hide-price="isPriceHidden"
        :add-successor-active="addSuccessorActive"
        @addToBasket="addToBasket"
        @quantityChange="changeQuantity"
        @onRequestClicked="openOnRequestLightBox"
      />
    </div>
    <div
      v-if="productBoxData.scoringInformation"
      v-html="productBoxData.scoringInformation"
    />
  </div>
</template>

<script lang="ts">
  import {defineComponent, PropType} from 'vue';
  import tracking from '../../utilities/js/tracking/Tracking';
  import type ProductBoxData from '../../types/ProductBoxData';
  import diContainer from '../../utilities/js/diContainer/diContainer';
  import {ProductBoxSettings} from './productBoxSettings';
  import pigeon from '../../utilities/js/pigeon/pigeon';
  import {isOnRequestWithoutPrice} from '../../utilities/js/onRequestType/OnRequestType'
  import jsGlobalConfig from '../../globals/jsGlobalConfig';

  export default defineComponent({
    name: 'ProductBox',

    props: {
      productBoxData: {
        type: Object as PropType<ProductBoxData>,
        required: true
      },
      hideCounterButton: {
        type: Boolean,
        default: false
      },
      state: {
        type: String,
        default: ''
      },
      trackingAdditional: {
        type: Object as PropType<{ [key: string]: any }>,
        default: () => ({})
      },
      showReview: {
        type: Boolean,
        default: false
      },
      isWishlistEnabled: {
        type: Boolean,
        default: true
      },
      lazyLoad: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        minimumRating: 3,
        basketUrl: jsGlobalConfig().getBasketUrl(),
        isOnRequestWithoutPrice: isOnRequestWithoutPrice,
        settings: diContainer.get('productBoxSettings') as ProductBoxSettings
      }
    },

    mounted() {
      tracking.registerProduct({
        ...this.trackingAdditional,
        ...this.productBoxData.trackingData
      });
    },

    computed: {
      foodSpecificationLink() {
        return this.productBoxData.url + this.$t('detail.product.characteristics.section');
      },

      subscriptionLink() {
        return this.productBoxData.url + '#section=' + this.$t('ads.main.head.subscription.title');
      },

      showAvailability() {
        const availability = this.productBoxData.availability;
        const isPurchaseOnRequestArticle = this.productBoxData.purchaseOnRequestArticle;
        return availability.statement
          && this.settings.showAvailabilityStatus.includes(availability.status)
          && !isPurchaseOnRequestArticle;
      },

      isBoxTypeVertical() {
        return this.productBoxData.productBoxType === 'VERTICAL' || this.productBoxData.productBoxType === 'SQUARE';
      },

      isBoxTypeHorizontal() {
        return this.productBoxData.productBoxType === 'HORIZONTAL';
      },

      isBottle() {
        return this.productBoxData.price && this.productBoxData.price.volumeUnit === 'BOTTLE';
      },

      productLink(): string|null {
        if (this.showReview && this.productBoxData.linked && this.productBoxData.valid) {
          return this.productBoxData.url + '#reviewForm';
        }

        if (this.productBoxData.linked && this.productBoxData.valid) {
          return this.productBoxData.url;
        }

        return null;
      },

      addSuccessorActive(): boolean {
        return !!(jsGlobalConfig().addSuccessorToBasket() && this.productBoxData.soldoutRelation && this.productBoxData.soldoutRelation.itemId);
      },

      isBoxBottle() {
        return this.productBoxData.price && this.productBoxData.price.volumeUnit === 'BOTTLE';
      },

      isPriceHidden() {
        if (this.productBoxData.availability.subscriptionPreview) {
          return true;
        }

        if (this.productBoxData.recurringOrderFollowUpPacket && !this.productBoxData.zeroPriceItem) {
          return !this.productBoxData.recurringOrderFollowUpPriceEnabled;
        }
        return isOnRequestWithoutPrice(this.productBoxData.purchaseOnRequestArticle);
      },
    },

    methods: {
      addToBasket(quantity: number) {
        this.$emit('addToBasket', this.productBoxData, quantity);
      },

      toggleWishlist() {
        if (this.productBoxData.inWishlist) {
          this.$emit('removeFromWishlist', this.productBoxData);
        } else {
          this.$emit('addToWishlist', this.productBoxData);
        }
      },

      changeQuantity(quantity: number) {
        this.$emit('quantityChange', this.productBoxData, quantity);
      },

      openOnRequestLightBox() {
        pigeon.publish(
          'lightbox:showUrl',
          window.__globalConfig.onRequestLightBoxUrl
        );
      },
    }
  });
</script>
